import styled from "styled-components"

export const Emphasis = styled.em`
  font-style: italic;
  display: inline;
  margin: 0;
  color: ${({ theme: { colors } }) => colors.dark[75]};
  font-family: "Merriweather", serif;
  font-weight: 400;

  ::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }
`
