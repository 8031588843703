import React, { FC } from "react"
import styled from "styled-components"

const Container = styled.div`
  text-align: center;

  ::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }
`

const Fact = styled.output`
  font-size: 5rem;
  color: ${({ theme: { colors } }) => colors.dark[100]};
`

const Description = styled.p`
  color: ${({ theme: { colors } }) => colors.dark[50]};
  font-weight: 300;
`

type Props = {
  fact: string | number
  description: string
  className?: string
}
export const KeyFact: FC<Props> = ({ fact, description, className }) => (
  <Container className={className}>
    <Fact>{fact}</Fact>
    <Description>{description}</Description>
  </Container>
)
